import kitchen from "../assets/imgs/kitchen.webp";
import bathroom from "../assets/imgs/bathroom.webp";
import interior from "../assets/imgs/interior.webp";

export const websiteName = "magicgranitecountertops";
export const companyName = "Magic Granite Countertops";
export const officialBusinessName = "Magic Granite & Quartz LLC"
export const companyPhoneNumber = "443-248-9480";
export const companyEmail = "hchilel132@gmail.com"
export const siteConsult = "https://www.descifle.com/";

export const navPaths = [
    {
        path: "/",
        name: "Home"
    },
    {
        path: "gallery",
        name: "Gallery"
    },
    {
        path: "contact",
        name: "Contact"
    }
];

export const whyChooseUsItems = [
    { title: "Free, At Home Consultations", text: "Request your free quote today and impress your guests this holiday season!" },
    { title: "Top Notch Service", text: "Fast and efficient service – perfect for getting your home holiday-ready!" },
    { title: "Licensed & Insured", text: "Fully insured for your peace of mind." }
]

export const servicesIncludeItems = [
    { title: "Kitchens", image: kitchen }, { title: "Bathrooms", image: bathroom }, { title: "Interiors", image: interior }
]

export const kitchenImgs = require.context("../assets/imgs/kitchen", false, /\.(webp)$/);
export const bathroomImgs = require.context("../assets/imgs/bathroom", false, /\.(webp)$/);
export const interiorImgs = require.context("../assets/imgs/interior", false, /\.(webp)$/);